
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue"

export default defineComponent({
  name: "profile",
  components: {
    Dropdown3,
    CompanyPolicy
  }
});
